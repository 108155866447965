import React from "react";
import AwardPage from "../../admin/Awards/Awards";
import Sidebar from "../../admin/sidebar/Sidebar";

function Awads() {
  return (
    <div>
      <Sidebar />
      <AwardPage />
    </div>
  );
}
 
export default Awads;
