import React from "react";
import Institution from "../../admin/institution/Intitution";
import Sidebar from "../../admin/sidebar/Sidebar";
function OurInstitution() {
  return (
    <div>
      <Sidebar/>
      <Institution />
    </div> 
  );
}

export default OurInstitution;
