import React from "react";
import Login from "../../admin/login/Login";

function LoginAdmin() {
  return (
    <div>
      <Login/>
    </div>
  );
} 

export default LoginAdmin;
 