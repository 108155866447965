import React from "react";
import Ownuniversities from "../../admin/ownuniversities/OwnUniversities";
import Sidebar from "../../admin/sidebar/Sidebar";
function OWNUniversities() {
  return (
    <div>
      <Sidebar />
      <Ownuniversities />
    </div>
  );
}

export default OWNUniversities;
